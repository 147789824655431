import { convertStringToDate } from '../../_app/utils/convertStringToDate'
import { nonNullableTypeSafe } from '../../_app/utils/helpers'
import type { JobPostResolvers } from '../../__codegen__/__graphql__/resolvers'
import { SALARY_TYPE, WORK_DAYS } from '../constants'
import type { ProtobufTimestamp } from '../../__codegen__/__openapi__/search-front-server'
import {
  IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum,
  IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum,
} from '../../__codegen__/__openapi__/search-front-server'

export const JobPost: JobPostResolvers = {
  id(parent) {
    return `JobPost#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  image(parent) {
    if (!parent.images?.length || !parent.images[0].thumbnail) {
      return null
    }
    return {
      thumbnail: parent.images[0].thumbnail,
    }
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  targetUri(parent) {
    return parent.targetUri ?? ''
  },
  workDates(parent) {
    if (!parent.workDates) {
      return []
    }
    const workDates = parent.workDates
      .map((workDate: ProtobufTimestamp) => {
        if (!workDate.iso) {
          return null
        }

        return workDate.iso
      })
      .filter(nonNullableTypeSafe)
    return workDates
  },
  workDays(parent) {
    if (!parent.workDays) {
      return []
    }
    const workDays = parent.workDays
      .map((workDay) => {
        switch (workDay) {
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.MON: {
            return WORK_DAYS.MON.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.TUE: {
            return WORK_DAYS.TUE.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.WED: {
            return WORK_DAYS.WED.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.THU: {
            return WORK_DAYS.THU.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.FRI: {
            return WORK_DAYS.FRI.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.SAT: {
            return WORK_DAYS.SAT.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.SUN: {
            return WORK_DAYS.SUN.ID
          }
          case IntegrateSearchSuccessResponseJobDocsInnerWorkDaysEnum.UNSPECIFIED:
          default: {
            return null
          }
        }
      })
      .filter(nonNullableTypeSafe)
    return workDays
  },
  authorBadges(parent) {
    if (!parent.authorBadges) {
      return []
    }
    return parent.authorBadges
  },
  salaryType(parent) {
    switch (parent.salaryType) {
      case IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum.MONTHLY: {
        return SALARY_TYPE.MONTHLY.ID
      }
      case IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum.DAILY: {
        return SALARY_TYPE.DAILY.ID
      }
      case IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum.HOURLY: {
        return SALARY_TYPE.HOURLY.ID
      }
      case IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum.PER_CASE: {
        return SALARY_TYPE.PER_CASE.ID
      }
      case IntegrateSearchSuccessResponseJobDocsInnerSalaryTypeEnum.UNSPECIFIED:
      default: {
        return SALARY_TYPE.UNKNOWN.ID
      }
    }
  },
  lastBringUpDate(parent) {
    return convertStringToDate(parent.lastBringUpDate?.iso)
  },
  publishedAt(parent) {
    return convertStringToDate(parent.publishedAt?.iso)
  },
  cursor(parent) {
    return parent.cursor ?? ''
  },
}
