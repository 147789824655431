import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const UserKeyword: Resolvers['UserKeyword'] = {
  id(parent) {
    return `UserKeyword#${parent.keyword}`
  },
  isBannedKeyword(parent) {
    return parent.isBannedKeyword
  },
  isRegistered(parent) {
    return parent.isRegistered
  },
  isNotificationBannedKeyword(parent) {
    return parent.isNotificationBannedKeyword
  },
}
