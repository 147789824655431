import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const HotKeywords: Resolvers['HotKeywords'] = {
  keywords(parent) {
    return parent.keywords
  },
  queryInfo(parent) {
    return {
      testGroup: parent.queryInfo?.testGroup ?? '',
    }
  },
}
