import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const KarrotGroupRegionRangesQueries: QueryResolvers = {
  async karrotGroupRegionRanges(_, args, ctx) {
    const resp = await ctx.services.aggregated.getRegionRanges({
      regionId: args.data.regionId,
    })

    if (!resp) {
      console.error('모임 지역범위를 불러오는데 실패했습니다.')

      return {
        regionRanges: [],
      }
    }

    return resp
  },
}
