import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const RecentSearch: Resolvers['RecentSearch'] = {
  eventTime(parent) {
    return parent.eventTime?.iso ?? ''
  },
  node(parent) {
    if (parent.shortcut) {
      return parent.shortcut
    }

    return {
      id: `RecentSearchQuery#${parent.searchQuery?.query || ''}`,
      query: parent.searchQuery?.query || '',
    }
  },
}

export const RecentSearchNode: Resolvers['RecentSearchNode'] = {
  __resolveType(parent) {
    if ('templateName' in parent) {
      return 'RecentSearchShortcut'
    } else {
      return 'RecentSearchQuery'
    }
  },
}

export const RecentSearchShortcut: Resolvers['RecentSearchShortcut'] = {
  id(parent) {
    return `RecentSearchShortcut#${parent.id}`
  },
  _id(parent) {
    return parent.id || '-1'
  },
  name(parent) {
    return parent.name || ''
  },
  imageUrl(parent) {
    return parent.image?.url || ''
  },
  title(parent) {
    return parent.title || ''
  },
  content(parent) {
    return parent.content || ''
  },
}
