import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'
import { IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum } from '../../__codegen__/__openapi__/search-front-server'
import { SEARCH_AD_MATERIAL_SOURCE_TYPE } from '../constants'

export const SearchBizAd: Resolvers['SearchBizAd'] = {
  id(parent) {
    return `SearchBizAd#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  bizProfile(parent) {
    return {
      ...parent.bizProfile,
      name: parent.bizProfile?.name ?? '',
      category: parent.bizProfile?.category ?? '',
      description: parent.bizProfile?.description ?? '',
      imageUrl: parent.bizProfile?.imageUrl ?? '',
      region: parent.bizProfile?.region ?? '',
      reviewCount: parent.bizProfile?.reviewCount ?? 0,
      followerCount: parent.bizProfile?.followerCount ?? 0,
      callCount: parent.bizProfile?.callCount ?? 0,
      chatCount: parent.bizProfile?.chatCount ?? 0,
      targetUri: parent.bizProfile?.targetUri ?? '',
      hasCoupon: parent.bizProfile?.hasCoupon ?? false,
      storeOnSale: parent.bizProfile?.isPurchasable ?? false,
      reservable: parent.bizProfile?.isReservable ?? false,
      deliberationCode: parent.bizProfile?.deliberationCode ?? '',
    }
  },
  materialSourceType(parent) {
    switch (parent.materialSourceType) {
      case IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum.BUSINESS_POST: {
        return SEARCH_AD_MATERIAL_SOURCE_TYPE.MATERIAL_SOURCE_TYPE_BUSINESS_POST
      }
      case IntegrateSearchSuccessResponseAdvertisementDocsInnerMaterialSourceTypeEnum.BUSINESS_PROFILE: {
        return SEARCH_AD_MATERIAL_SOURCE_TYPE.MATERIAL_SOURCE_TYPE_BUSINESS_PROFILE
      }
      default: {
        return SEARCH_AD_MATERIAL_SOURCE_TYPE.MATERIAL_SOURCE_TYPE_UNSPECIFIED
      }
    }
  },
}
