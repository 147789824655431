import type { NeighborAdResolvers } from '../../__codegen__/__graphql__/resolvers'
import type { FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInner } from '../../__codegen__/__openapi__/search-front-server'
import {
  FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum,
  FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum,
} from '../../__codegen__/__openapi__/search-front-server'

export const NeighborAd: NeighborAdResolvers = {
  id(parent) {
    return `NeighborAd#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  title(parent) {
    return parent.title ?? ''
  },
  regionId(parent) {
    return parent.regionId ?? ''
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  createdAt(parent) {
    if (!parent.createdAt?.iso) {
      return
    }

    return parent.createdAt.iso
  },
  publishedAt(parent) {
    if (!parent?.publishedAt?.iso) {
      return
    }

    if (!parent.publishedAt.iso) {
      return
    }

    return parent.publishedAt.iso
  },
  targetUri(parent) {
    return parent.targetUri ?? ''
  },
  firstImage(parent) {
    return parent.firstImage
      ? {
          url: parent.firstImage.url,
          thumbnail: parent.firstImage.thumbnail,
        }
      : null
  },
  status(parent) {
    switch (parent.status) {
      case FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum.ONGOING:
        return 'ONGOING'
      case FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum.RESERVED:
        return 'RESERVED'
      case FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum.CLOSED:
        return 'CLOSED'
      case FleamarketSearchSuccessResponseDocumentsInnerComponentNeighborAdStatusEnum.UNSPECIFIED:
      default:
        return 'UNKNOWN'
    }
  },
  price(parent) {
    return parent.price ?? 0
  },
  chatRoomsCount(parent) {
    return parent.chatRoomCount ?? 0
  },
  watchesCount(parent) {
    return parent.watchCount ?? 0
  },
  info(parent) {
    return {
      button: {
        text: parent.info?.button?.text ?? '',
        targetUri: parent.info?.button?.targetUri ?? '',
        logging: {
          impression: parent.info?.button?.logging?.impression ?? '',
          click: parent.info?.button?.logging?.click ?? '',
        },
      },
      logging: {
        impression: parent.info?.logging?.impression ?? '',
        click: parent.info?.logging?.click ?? '',
      },
    }
  },
  logging(parent) {
    return {
      impression: parent.logging?.impression ?? '',
      click: parent.logging?.click ?? '',
    }
  },
  hideUrl(parent) {
    /**
     * @todo 추후 서버에서 제공하는 parent.hideUrl 변경해야 함
     */
    const hideAction = parent.overflows?.find(
      (
        overflow: FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInner
      ) => {
        return (
          overflow.action?.type ===
          FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum.HIDE
        )
      }
    )
    return hideAction?.action?.url ?? ''
  },
  reportUrl(parent) {
    /**
     * @todo 추후 서버에서 제공하는 parent.reportUrl로 변경해야 함
     */
    const reportAction = parent.overflows?.find(
      (
        overflow: FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInner
      ) => {
        return (
          overflow.action?.type ===
          FleamarketSearchSuccessResponseDocumentsInnerComponentCatalogAdvertisementOverflowsInnerActionTypeEnum.TARGET
        )
      }
    )
    return reportAction?.action?.url ?? ''
  },
}
