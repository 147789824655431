import type {
  RelatedKeywordResolvers,
  RelatedKeywordsResolvers,
} from '../../__codegen__/__graphql__/resolvers'
import {
  FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum,
  FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum,
} from '../../__codegen__/__openapi__/search-front-server'

export const RelatedKeywords: RelatedKeywordsResolvers = {
  keywords(parent) {
    return parent.keywords ?? []
  },
  template(parent) {
    switch (parent.template) {
      case FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum.LIST_VIEW:
        return 'LIST_VIEW'
      default:
        return 'UNKNOWN'
    }
  },
}

export const RelatedKeyword: RelatedKeywordResolvers = {
  type(parent) {
    switch (parent.type) {
      case FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum.KEYWORD:
        return 'KEYWORD'
      case FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum.SHORTCUT:
        return 'SHORTCUT'
      case FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum.UNSPECIFIED:
      default:
        return 'UNSPECIFIED'
    }
  },
}
