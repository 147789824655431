import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchUser: Resolvers['SearchUser'] = {
  id(parent) {
    return `SearchUser#${parent.docId}`
  },
  _id(parent) {
    return Number(parent.docId)
  },
  image(parent) {
    return parent.image
      ? {
          url: parent.image.url,
          thumbnail: parent.image.thumbnail,
        }
      : null
  },
}
