import type {
  SearchCommunityRecommendedLocalProfileResolvers,
  SearchCommunityRecommendedLocalProfilesResolvers,
} from '../../__codegen__/__graphql__/resolvers'

export const SearchCommunityRecommendedLocalProfiles: SearchCommunityRecommendedLocalProfilesResolvers =
  {
    localProfiles(parent) {
      return parent.source?.localProfiles ?? []
    },
  }

export const SearchCommunityRecommendedLocalProfile: SearchCommunityRecommendedLocalProfileResolvers =
  {
    id(parent) {
      return `CommunityRecommendedLocalProfile#${parent.id}`
    },
    _id(parent) {
      return parent.id
    },
    name(parent) {
      return parent.name
    },
    summary(parent) {
      return parent.summary
    },
  }
