import type { RealtyResolvers } from '../../__codegen__/__graphql__/resolvers'
import { IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum } from '../../__codegen__/__openapi__/search-front-server'

export const Realty: RealtyResolvers = {
  id(parent) {
    return `Realty#${parent.docId}`
  },
  _id(parent) {
    return parent.id ?? ''
  },
  salesTypeTitle(parent) {
    return parent.salesTypeTitle ?? ''
  },
  priceTitle(parent) {
    return parent.priceTitle ?? ''
  },
  locationTitle(parent) {
    return parent.locationTitle ?? ''
  },
  chatCount(parent) {
    return parent.chatCount ?? 0
  },
  voteCount(parent) {
    return parent.voteCount ?? 0
  },
  status(parent) {
    switch (parent.articleStatus) {
      case IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum.ON_SALE:
        return 'ON_SALE'
      case IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum.RESERVED:
        return 'RESERVED'
      case IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum.TRADE_COMPLETED:
        return 'TRADE_COMPLETED'
      case IntegrateSearchSuccessResponseRealtyDocsInnerArticleStatusEnum.UNSPECIFIED:
      default:
        return 'UNKNOWN'
    }
  },
  otherSalesTypes(parent: any) {
    if (parent.otherSalesTypeTags && parent.otherSalesTypeTags.length > 0) {
      return parent.otherSalesTypeTags.filter(Boolean)
    }

    if (
      parent.otherSalesTypeTagsList &&
      parent.otherSalesTypeTagsList.length > 0
    ) {
      return parent.otherSalesTypeTagsList.filter(Boolean)
    }

    return []
  },
  createdAt(parent) {
    if (!parent.createdAt?.iso) {
      return
    }

    return parent.createdAt.iso
  },
  publishedAt(parent) {
    if (!parent.publishedAt?.iso) {
      return
    }

    return parent.publishedAt.iso
  },
  pullUpCount(parent) {
    return parent.pullUpCount ?? 0
  },
  isWriterVerified(parent) {
    return parent.isWriterVerified ?? false
  },
  picture(parent) {
    return parent.picture
      ? {
          thumbnail: parent.picture.thumbnail,
        }
      : null
  },
}
