import type { SearchPlaceNodeResolvers } from '../../__codegen__/__graphql__/resolvers'
import { PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum } from '../../__codegen__/__openapi__/search-front-server'

import { PLACE_COMPONENT_TYPE } from '../constants'

export const SearchPlaceNode: SearchPlaceNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum.PLACE: {
        return PLACE_COMPONENT_TYPE.PLACE
      }
      case PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum.BANNER: {
        return PLACE_COMPONENT_TYPE.PLACE_CREATE_BANNER
      }
      case PlaceSearchSuccessResponseDocumentsInnerComponentTypeEnum.UNSPECIFIED:
      default: {
        return PLACE_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
}
