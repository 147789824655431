import {
  CAR_OPTION_NAME,
  CAR_STATUS,
  CAR_HISTORY_CHANGE_TYPE,
} from '../constants'
import type {
  SearchCarFilterResolvers,
  SearchCarResolvers,
} from '../../__codegen__/__graphql__/resolvers'
import type { IntegrateSearchSuccessResponseCarDocsInnerCarDescription } from '../../__codegen__/__openapi__/search-front-server'
import {
  IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum,
  IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum,
  IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum,
} from '../../__codegen__/__openapi__/search-front-server'

const carStatusEnumMapper = (
  carStatus?: IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum
) => {
  if (!carStatus) return CAR_STATUS.NONE
  switch (carStatus) {
    case IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum.RESERVE: {
      return CAR_STATUS.RESERVE
    }
    case IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum.SALE: {
      return CAR_STATUS.SALES_STATUS
    }
    case IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum.TRADED: {
      return CAR_STATUS.TRADED
    }
    case IntegrateSearchSuccessResponseCarDocsInnerCarStatusEnum.UNSPECIFIED:
    default: {
      return CAR_STATUS.NONE
    }
  }
}

const carOptionNameEnumMapper = (
  carOptionName?: IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum
) => {
  if (!carOptionName) return CAR_OPTION_NAME.NOT_SPECIFIED
  switch (carOptionName) {
    case IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum.SMOKING_FREE: {
      return CAR_OPTION_NAME.SMOKING_FREE
    }
    case IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum.UNSPECIFIED:
    default: {
      return CAR_OPTION_NAME.NOT_SPECIFIED
    }
  }
}

const carHistoryChangeTypeEnumMapper = (
  carHistoryChange: IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum
) => {
  if (!carHistoryChange) return CAR_HISTORY_CHANGE_TYPE.UNKNOWN
  switch (carHistoryChange) {
    case IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum.REGISTER: {
      return CAR_HISTORY_CHANGE_TYPE.REGISTER
    }
    case IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum.CAR_NO: {
      return CAR_HISTORY_CHANGE_TYPE.CAR_NO
    }
    case IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum.OWNER: {
      return CAR_HISTORY_CHANGE_TYPE.OWNER
    }
    default: {
      return CAR_HISTORY_CHANGE_TYPE.UNKNOWN
    }
  }
}

export const SearchCar: SearchCarResolvers = {
  id(parent) {
    return `SearchCar#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  title(parent) {
    return parent.title ?? ''
  },
  price(parent) {
    return parent.price ?? 0
  },
  displayPrice(parent) {
    return parent.displayPrice ?? ''
  },
  content(parent) {
    return parent.content ?? ''
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  carStatus(parent) {
    return carStatusEnumMapper(parent.carStatus)
  },
  carOptionNamesList(parent) {
    if (!parent.carOptionNames) return []

    const carOptionNamesListMapper = (
      list: IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum[]
    ) => {
      return list.map(
        (item: IntegrateSearchSuccessResponseCarDocsInnerCarOptionNamesEnum) =>
          carOptionNameEnumMapper(item)
      )
    }

    return carOptionNamesListMapper(parent.carOptionNames)
  },
  chatCount(parent) {
    return parent.chatCount ?? 0
  },
  voteCount(parent) {
    return parent.voteCount ?? 0
  },
  firstImage(parent) {
    return parent.firstImage
      ? {
          thumbnail: parent.firstImage.thumbnail,
        }
      : null
  },
  pullUpCount(parent) {
    return parent.pullUpCount ?? 0
  },
  createdAt(parent) {
    if (!parent?.createdAt?.iso) {
      return
    }

    return parent.createdAt.iso
  },
  updatedAt(parent) {
    if (!parent.updatedAt?.iso) {
      return
    }

    return parent.updatedAt.iso
  },
  publishedAt(parent) {
    if (!parent.publishedAt?.iso) {
      return
    }

    return parent.publishedAt.iso
  },
  carData(parent) {
    const carDataMapper = (
      carData: IntegrateSearchSuccessResponseCarDocsInnerCarDescription,
      carHistoryChangesList: IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum[]
    ) => {
      return {
        driveDistance: carData.driveDistance ?? 0,
        modelYear: carData.modelYear ?? 0,
        carName: carData.carName ?? '',
        carHistoryChangesList: carHistoryChangesList.map(
          (
            item: IntegrateSearchSuccessResponseCarDocsInnerCarDescriptionChangeHistoriesEnum
          ) => carHistoryChangeTypeEnumMapper(item)
        ),
      }
    }

    if (!parent.carDescription) {
      return null
    }
    const carData = {
      driveDistance: parent.carDescription.driveDistance ?? 0,
      modelYear: parent.carDescription.modelYear ?? 0,
      carName: parent.carDescription.carName ?? '',
      carHistoryChangesList: [],
    }

    if (parent.carDescription.changeHistories) {
      return carDataMapper(carData, parent.carDescription.changeHistories)
    }
    return carData
  },
}

export const SearchCarFilter: SearchCarFilterResolvers = {
  companyIdsList(parent: any) {
    if (!parent.companyIds) {
      return []
    }
    return parent.companyIds
  },
  seriesIdsList(parent: any) {
    if (parent.seriesIds) {
      return []
    }
    return parent.seriesIds
  },
  subseriesIdsList(parent: any) {
    if (parent.subseriesIds) {
      return []
    }
    return parent.subseriesIds
  },
}
