import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const RegionQueries: Resolvers['Query'] = {
  /**
   * 중고거래 필터 지역 범위
   */
  async regionRanges(parent, args, ctx) {
    const regionRanges = await ctx.services.karrotMarket.getRegionRanges({
      regionId: args.where.regionId,
    })

    if (!regionRanges) {
      return []
    }

    return regionRanges
  },
}
