import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const CategoryQueries: QueryResolvers = {
  async fleaMarketCategories(parent, args, ctx) {
    const resp = await ctx.services.category.getFleaMarketCategoryList({
      referrer: { experiment: ctx.experiment },
    })

    return (resp?.categories ?? []).filter(Boolean)
  },
}
