import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchBusinessPost: Resolvers['SearchBusinessPost'] = {
  id(parent) {
    return `SearchBusinessPost#${parent.docId}`
  },
  _id(parent) {
    return parent.docId ?? ''
  },
  userType(parent) {
    return parent.userType ?? ''
  },
  createdAt(parent) {
    // NOTE: 마이그레이션 전 통합탭 위한 코드
    if (!parent.createdAt) {
      return
    }

    // NOTE: 마이그레이션 전 통합탭 위한 코드
    if (typeof parent.createdAt === 'string') {
      return new Date(parent.createdAt).toISOString()
    }

    if (!parent.createdAt?.iso) {
      return
    }

    return parent.createdAt.iso
  },
  title(parent) {
    return parent.title ?? ''
  },
  hasCoupon(parent) {
    return parent.hasCoupon ?? false
  },
  categoryId(parent) {
    return parent.categoryId ?? ''
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  bookmarkCount(parent) {
    return parent.bookmarkCount ?? 0
  },
}
