import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const RecentSearchMutations: Resolvers['Mutation'] = {
  async deleteAllRecentSearchQuery(parent, args, ctx) {
    const resp = await ctx.services.recentSearch.deleteAllRecentQuery({
      referrer: {
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error()
    }

    return {
      result: resp.result,
      error: resp.error,
    }
  },

  async deleteRecentSearchQuery(parent, args, ctx) {
    const resp = await ctx.services.recentSearch.deleteRecentKeyword({
      query: args.input.query,
      referrer: {
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error()
    }

    return {
      result: resp.result,
      error: resp.error,
    }
  },

  async deleteRecentSearchShortcut(parent, args, ctx) {
    const resp = await ctx.services.recentSearch.deleteRecentShortcut({
      shortcutId: args.input.shortcutId,
      referrer: {
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error()
    }

    return {
      result: resp.result,
      error: resp.error,
    }
  },
}

export const RecentSearchMutationOutput: Resolvers['RecentSearchMutationOutput'] =
  {
    __resolveType(parent) {
      if ('result' in parent) {
        return 'RecentSearchMutationOutputResult'
      } else {
        return 'RecentSearchMutationOutputError'
      }
    },
  }
