import { take } from 'lodash-es'
import type {
  InputMaybe,
  QueryResolvers,
  SearchBusinessPostFilterInputSort,
} from '../../__codegen__/__graphql__/resolvers'
import { ApiV1BizPostSearchPostRequestSortEnum } from '../../__codegen__/__openapi__/search-front-server'
import { BUSINESS_FILTER_SORTS } from '../stores/businessPostFilterSlice'

export const SearchBusinessPostQueries: QueryResolvers = {
  /**
   * 동네홍보 검색 결과
   */
  async searchBusinessPosts(parent, args, ctx) {
    const first = args.first + 1

    const resp = await ctx.services.businessPost.getBusinessPosts({
      query: args.data.query,
      regionId: args.data.regionId.toString(),
      pageSize: first,
      pageToken: args.after ?? undefined,
      sort: convertSortToBusinessPostSort(args.data.sort ?? undefined),
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      throw new Error('Failed to business posts search result')
    }

    return {
      edges: take(resp.connection.edges, args.first),
      pageInfo: {
        hasNextPage: resp.connection.edges.length > args.first,
        hasPreviousPage: false,
        startCursor: resp.connection.edges[0]?.cursor ?? null,
        endCursor: resp.connection.edges[args.first - 1]?.cursor ?? null,
      },
      experiment: resp.experiment,
      error: resp.error,
    }
  },
}

const convertSortToBusinessPostSort = (
  sort: InputMaybe<SearchBusinessPostFilterInputSort> | undefined
) => {
  switch (sort) {
    case BUSINESS_FILTER_SORTS.RECENT.ID:
      return ApiV1BizPostSearchPostRequestSortEnum.RECENT
    case BUSINESS_FILTER_SORTS.RELEVANT.ID:
      return ApiV1BizPostSearchPostRequestSortEnum.RELEVANT
    default:
      return ApiV1BizPostSearchPostRequestSortEnum.UNSPECIFIED
  }
}
