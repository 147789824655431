import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const KeywordNotificationQueries: QueryResolvers = {
  /**
   * 중고거래 키워드 알림
   */
  async userKeyword(parent, args, ctx) {
    const resp =
      await ctx.services.keywordNotification.getKeywordNotificationInfo({
        keyword: args.data.query,
        referrer: { experiment: ctx.experiment },
      })

    if (!resp) {
      throw new Error('User Keyword를 불러오는데 실패했습니다.')
    }

    return resp
  },
}
