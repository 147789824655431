import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const Category: Resolvers['Category'] = {
  id(parent) {
    return `Category#${parent.id}`
  },
  _id(parent) {
    return Number(parent.id)
  },
}
