import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const RegionRange: Resolvers['RegionRange'] = {
  name(parent) {
    switch (parent.name) {
      case 'my':
        return 'MY'
      case 'adjacent':
        return 'ADJACENT'
      case 'range2':
        return 'RANGE_2'
      case 'range3':
        return 'RANGE_3'
      default:
        return 'UNKNOWN'
    }
  },
}
