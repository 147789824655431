import type { SearchCommunityResolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchCommunity: SearchCommunityResolvers = {
  id(parent) {
    return `Community#${parent.meta?.docId ?? ''}`
  },
  _id(parent) {
    return parent.source?.id ?? ''
  },
  categoryId(parent) {
    return parent.source?.categoryId ?? ''
  },
  categoryName(parent) {
    return parent.source?.categoryName ?? ''
  },
  interestName(parent) {
    return parent.source?.interestName ?? ''
  },
  interestUid(parent) {
    return parent.source?.interestUid ?? ''
  },
  title(parent) {
    return parent.source?.title ?? ''
  },
  content(parent) {
    return parent.source?.content ?? ''
  },
  viewCount(parent) {
    return parent.source?.viewCount ?? 0
  },
  commentsCount(parent) {
    return parent.source?.commentCount ?? 0
  },
  createdAt(parent) {
    if (!parent.source?.createdAt?.iso) {
      return
    }

    return parent.source.createdAt.iso
  },
  imagesCount(parent) {
    return parent.source?.imageCount ?? 0
  },
  emoticonsCount(parent) {
    return parent.source?.emoticonCount ?? 0
  },
  regionName(parent) {
    return parent.fetch?.regionName ?? ''
  },
  firstImage(parent) {
    return parent.fetch?.presentationImage
      ? {
          url: parent.fetch.presentationImage.url,
          thumbnail: parent.fetch.presentationImage.thumbnail,
          medium: parent.fetch.presentationImage.medium,
        }
      : null
  },
  titleHighlight(parent) {
    return parent.meta?.titleHighlight ?? ''
  },
  contentHighlight(parent) {
    return parent.meta?.contentHighlight ?? ''
  },
  commentHighlight(parent) {
    return parent.meta?.commentHighlight ?? ''
  },
}
