import type { CatalogProductAdNodeResolvers } from '../../__codegen__/__graphql__/resolvers'
import { IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum } from '../../__codegen__/__openapi__/search-front-server'

import { CATALOG_PRODUCT_AD_COMPONENT_TYPE } from '../constants'

export const CatalogProductAdNode: CatalogProductAdNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum.CATALOG_ADVERTISEMENT: {
        return CATALOG_PRODUCT_AD_COMPONENT_TYPE.CATALOG_ADVERTISEMENT
      }
      case IntegrateSearchSuccessResponseDspAdvertisementDocsInnerComponentTypeEnum.CAROUSEL_ADVERTISEMENT: {
        return CATALOG_PRODUCT_AD_COMPONENT_TYPE.CAROUSEL_ADVERTISEMENT
      }
      default: {
        return CATALOG_PRODUCT_AD_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
}
